import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useId,
} from 'react';
import { Search as SearchIcon } from '@hxm/assets/svg/ui/Search';
import type { InputProps } from '@hxm/types/input-prop-types';
import classnames from 'classnames/bind';

import { Tooltip } from '../tooltip/Tooltip';

import { Message } from './Message';

import 'tippy.js/dist/tippy.css';
import s from './TextInput.module.scss';

const c = classnames.bind(s);

type TextInputTypes =
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'price'
  | 'tel'
  | 'text'
  | 'ssn'
  | 'time'
  | 'url'
  | 'week'
  | 'file';

type OwnProps = {
  inputType?: TextInputTypes;
  floatLabel?: boolean;
  isFocused?: boolean;
  helperMessage?: string;
  messageVariant?: 'info' | 'error';
  login?: boolean;
  spacer?: boolean;
  renderRightOfInput?: () => ReactNode;
  /* `default` (sjóðfélagar) 48px, `small` 32px, `medium` 40px, `large` 56px */
  shape?: 'default' | 'small' | 'medium' | 'large';
  inputContainerClassName?: string;
  inputClassName?: string;
  hideLabel?: boolean;
};

export type TextInputProps = OwnProps &
  InputProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    inputType = 'text',
    className,
    label,
    message,
    error,
    floatLabel = true,
    id,
    isFocused,
    login,
    helperMessage,
    spacer,
    renderRightOfInput,
    type,
    placeholder,
    shape = 'default',
    messageVariant,
    inputClassName,
    inputContainerClassName,
    hideLabel,
    ...rest
  } = props;

  const uid = useId();
  const inputId = id ?? uid;

  const renderPlaceholder =
    placeholder !== undefined
      ? placeholder
      : typeof label === 'string'
      ? label
      : undefined;
  const input = (
    <input
      type={['ssn', 'number', 'price'].includes(inputType) ? 'tel' : inputType}
      className={c(s.textInput__input, shape, inputClassName, {
        textInput__input__focus: isFocused,
        login,
      })}
      {...rest}
      ref={ref}
      id={inputId}
      placeholder={renderPlaceholder}
      aria-describedby={message ? `${inputId}-error` : ''}
      pattern={
        type === 'email' ? '^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,4}$' : undefined
      }
    />
  );

  return (
    <div
      className={c(s.textInput, className, inputType, shape, {
        hideLabel,
        hasLabel: typeof label === 'string' && label && label.length > 0,
        error,
        hideFloatLabel: !floatLabel,
        login,
      })}
    >
      {login ? (
        <>
          {input}
          {renderRightOfInput?.()}
          <label className={c(s.textInput__label, { login })} htmlFor={inputId}>
            {label}
          </label>
          {helperMessage && <Tooltip tooltip={helperMessage} />}
        </>
      ) : (
        <>
          <div className={s.textInput__labelContainer}>
            <label className={s.textInput__label} htmlFor={inputId}>
              {label}
            </label>
            {helperMessage && <Tooltip tooltip={helperMessage} />}
          </div>

          <div className={c(s.textInput__inputContainer, inputContainerClassName)}>
            {input}
            {renderRightOfInput?.()}
          </div>
        </>
      )}

      {message && (
        <Message messageVariant={messageVariant} id={`${inputId}-error`}>
          {message}
        </Message>
      )}

      {inputType === 'search' && <SearchIcon className={s.textInput__icon} />}

      {spacer && <div className={s.textInput__spacer} />}
    </div>
  );
});
