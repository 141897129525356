import { ChangeEvent, useCallback, useState } from 'react';
import { Step2Result, useAudkenniFetch } from '@hxm/audkenni/client/audkenniFetch';
import {
  audkenniPoller,
  AuthResult,
  CheckAudkenniRouteResult,
} from '@hxm/audkenni/client/poller';
import { Button } from '@hxm/components/button/Button';
import { FormLayout } from '@hxm/components/form-elements/FormLayout';
import { SsnInput } from '@hxm/components/form-elements/SsnInput';
import { TelephoneInput } from '@hxm/components/form-elements/TelephoneInput';
import { H1, H4 } from '@hxm/components/heading/Heading';
import { Login } from '@hxm/components/login/Login';
import { LoginTabs } from '@hxm/components/login/LoginTabs';
import Modal from '@hxm/components/modal/Modal';
import { Picture } from '@hxm/components/picture/Picture';
import { frontpageMessages } from '@hxm/contentful/messages/messages.frontpage';
import { useMessages } from '@hxm/hooks/useMessages';
import { AudkenniJob } from '@hxm/joakim/iron.d';
import { clientFetch, swrFetcher } from '@hxm/utils/clientFetch';
import { logger } from '@hxm/utils/logger';
import * as Sentry from '@sentry/nextjs';
import { Locale } from 'contentful-locale';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import useSWR from 'swr';

const testUsers = process.env.NEXT_PUBLIC_TEST_USERS?.split(';');
const isDev = process.env.NODE_ENV === 'development';
export type PageProps = {
  image: string | null;
  telAuth: boolean;
};

// eslint-disable-next-line complexity
export const FrontpageContainer = ({ frontpage }: { frontpage: PageProps }) => {
  const { fetchAudkenni: checkAudkenni } = useAudkenniFetch<
    Step2Result & { choice: number },
    CheckAudkenniRouteResult
  >('/api/audkenni/checkAuthentication');
  const { fetchAudkenni: initiateAudkenni } = useAudkenniFetch<
    { nationalRegistry: string } | { phoneNumber: string },
    AudkenniJob
  >('/api/audkenni/initiateAuth');

  const [tab, setTab] = useState(isDev && testUsers ? 0 : 1);
  const { formatMessage } = useMessages();
  const [loginTelephone, setLoginTelephone] = useState('');
  const [nationalRegistry, setNationalRegistry] = useState('');
  const [authInProgress, setAuthInProgress] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
  const [authFailedReason, setAuthFailedReason] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const router = useRouter();
  const { data, error } = useSWR<Array<Locale>>('/api/locales/', swrFetcher);
  const { publicRuntimeConfig } = getConfig();
  const { version } = publicRuntimeConfig ?? 0;

  const buttonDisabled =
    authInProgress ||
    (tab === 1 && loginTelephone.length !== 7) ||
    (tab === 2 && nationalRegistry.length !== 10);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      tab === 1 ? setLoginTelephone(e.target.value) : setNationalRegistry(e.target.value);
    },
    [tab]
  );

  const handleAudkenniLogin = async (
    input: { phoneNumber: string } | { nationalRegistry: string }
  ) => {
    const choice = 'phoneNumber' in input ? 0 : 2;
    if (!authInProgress) {
      setAuthInProgress(true);
      initiateAudkenni(input)
        .then((response) => {
          if (response) {
            if ('error' in response) {
              setAuthInProgress(false);
              if (response.error instanceof Error) {
                setAuthFailedReason(response.error.message);
              } else {
                setAuthFailedReason('Óþekkt villa við auðkenningu');
              }
              logger.error('authentication failed', {
                exception: response.error as Error,
              });
            } else {
              setVerificationCode(response.data.verificationCode);
              audkenniPoller({ ...response.data, choice }, checkAudkenni)
                .then((r) => {
                  setAuthInProgress(false);
                  setVerificationCode('');
                  if (r.success) {
                    // joakim login
                    router.push(r.redirect).catch(Sentry.captureException);
                  } else {
                    if (r.message) {
                      setAuthFailedReason(r.message);
                    }
                    setModalActive(true);
                  }
                })
                .catch((e: Error) => {
                  setAuthInProgress(false);
                  Sentry.captureException(e);
                  if (e.message) {
                    setAuthFailedReason(e.message);
                  }
                  setModalActive(true);
                });
            }
          }
        })
        .catch((e) => {
          setAuthInProgress(false);
          logger.error('authentication failed', { exception: e as Error });
        });
    }
  };

  const loginTabs: Array<{ id: number; title: string }> = [
    {
      id: 1,
      title: formatMessage(frontpageMessages.telAuthTitle) ?? 'Rafræn skilríki',
    },
  ];

  // TODO: Add audkenni to settings
  if (process.env.NEXT_PUBLIC_USE_AUDKENNI === 'true') {
    loginTabs.push({
      id: 2,
      title: formatMessage(frontpageMessages.appAuthTitle) ?? 'Auðkenning í appi',
    });
  }

  if (isDev && testUsers) {
    loginTabs.unshift({
      id: 0,
      title: 'Fake login',
    });
  }

  const locales = !data && !error ? undefined : data?.map((locale) => locale.code);

  return (
    <Login
      locales={locales}
      selectedLocale={router.locale}
      image={<Picture src={frontpage.image ?? ''} />}
    >
      <H1>{formatMessage(frontpageMessages.title)}</H1>

      <LoginTabs
        tabs={loginTabs}
        setTab={(id) => {
          setTab(id);
          id === 1 ? setNationalRegistry('') : setLoginTelephone('');
        }}
        active={tab}
      />

      {isDev && testUsers && tab === 0 && (
        <FormLayout>
          {testUsers.map((kt, i) => (
            <Button
              key={i}
              loading={authInProgress}
              onClick={() => {
                if (!authInProgress) {
                  setAuthInProgress(true);
                  clientFetch<AuthResult>('/api/auth/impersonate', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ssn: kt }),
                  })
                    .then((res) => {
                      setAuthInProgress(false);
                      if (res?.success) {
                        router.push(res.redirect).catch(Sentry.captureException);
                      }
                    })
                    .catch(Sentry.captureException);
                }
              }}
            >
              Login user {kt}
            </Button>
          ))}
        </FormLayout>
      )}

      {tab === 1 && (
        <FormLayout>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAudkenniLogin({ phoneNumber: loginTelephone });
            }}
          >
            <TelephoneInput
              login
              autoFocus
              label={formatMessage(frontpageMessages.telAuthPlaceholder)}
              name="tel"
              value={loginTelephone}
              onChange={onInputChange}
              placeholder={formatMessage(frontpageMessages.telAuthPlaceholder)}
            />

            <Button
              type="submit"
              size="large"
              loading={authInProgress}
              disabled={loginTelephone.length !== 7 || authInProgress}
            >
              {formatMessage(frontpageMessages.telAuthButton)}
            </Button>
          </form>
        </FormLayout>
      )}
      {tab === 2 && (
        <FormLayout>
          <FormLayout>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAudkenniLogin({ nationalRegistry: nationalRegistry });
              }}
            >
              <SsnInput
                login
                autoFocus
                label={formatMessage(frontpageMessages.appAuthPlaceholder)}
                name="ssn"
                value={nationalRegistry}
                onChange={onInputChange}
                placeholder={formatMessage(frontpageMessages.appAuthPlaceholder)}
              />
              {!!verificationCode && (
                <H4 style={{ paddingTop: '2em' }}>
                  {formatMessage(frontpageMessages.appAuthVerificationCode)}{' '}
                  {verificationCode}
                </H4>
              )}

              <Button
                type="submit"
                size="large"
                loading={authInProgress}
                disabled={buttonDisabled}
              >
                {formatMessage(frontpageMessages.appAuthButton)}
              </Button>
            </form>
          </FormLayout>
        </FormLayout>
      )}

      {modalActive && (
        <Modal
          startOpen={false}
          onClosed={() => {
            setAuthFailedReason('');
            setModalActive(false);
          }}
          portal={false}
        >
          <H1>{formatMessage(frontpageMessages.failedAuthTitle)}</H1>
          <p>{authFailedReason || formatMessage(frontpageMessages.failedAuthText)}</p>
        </Modal>
      )}
      <div style={{ display: 'none' }}>v{version}</div>
    </Login>
  );
};
